<template>
  <div class="login d-flex justify-center">
  </div>
</template>
<script>
import Vue from 'vue';
import { LSS } from '@core/services';
import { API_ORY_URL } from '@config'
  export default Vue.extend({
    name: 'Logout',
    data () {
      return {
        logged : false
      }
    },
    mounted() {
      LSS.clear();
      window.location.href = Vue.prototype.$out_url
    }
  })
</script>
